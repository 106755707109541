<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="link"
  >
    <a
      :href="href"
      :class="containerClasses"
      @click="navigate"
    >
      <thread-heading :thread="thread" />
      <div class="item-content">
        <h2>{{ thread.title }}</h2>
        <div class="item-footer">
          <span class="continue-reading">
            Continuar leyendo
          </span>
          <div
            v-if="thread.comments.length > 0"
            class="item-responses"
          >
            <span class="last-response">
              Última respuesta {{ lastCommentDate }}
            </span>
            <avatar-sandwich :users-data="commentUsersData" />
          </div>
          <div
            v-else
            class="item-responses"
          >
            <span class="last-response">
              Aún no hay respuestas para este post
            </span>
          </div>
        </div>
      </div>
    </a>
  </router-link>
</template>

<script>
import { format } from 'timeago.js'
import { mapState } from 'vuex'

import AvatarSandwich from '../AvatarSandwich.vue'
import ThreadHeading from './ThreadHeading.vue'

export default {
  name: 'ThreadListingItem',
  components: {
    AvatarSandwich,
    ThreadHeading
  },
  props: {
    detailRouteName: {
      type: String,
      required: true
    },
    projectIdentifier: {
      type: String,
      required: true
    },
    slim: {
      type: Boolean,
      default: false
    },
    thread: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('participationProject', ['projectData']),
    link() {
      return {
        name: this.detailRouteName,
        params: {
          projectIdentifier: this.projectIdentifier,
          threadIdentifier: this.thread.identifier
        }
      }
    },
    containerClasses() {
      return `thread-listing-item white-box${this.slim ? ' slim' : ''}`
    },
    lastCommentDate() {
      if (this.thread.comments.length > 0) {
        return format(this.thread.comments[0].created, 'es')
      }

      return null
    },
    commentUsersData() {
      // Replace Comment author identifiers for Comment identifiers to avoid repeating keys
      return this.thread.comments.map((comment) => ({
        ...comment.author, identifier: comment.identifier
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.thread-listing-item {
  display: block;
  text-align: left;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &, &:hover {
    color: #333;
  }
}

.item-content {
  padding-left: 40px;
}

h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 20px;
}

.item-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .continue-reading {
    background: #e7f0ff;
    display: block;
    color: $blue;
    font-size: 16px;
    line-height: 33px;
    height: 33px;
    white-space: nowrap;
    border-radius: 8px;
    padding: 0 13px;
  }

  .item-responses {
    display: flex;
    align-items: center;

    .last-response {
      font-size: 12px;
    }

    .avatar-sandwich {
      margin-left: 10px;
    }
  }
}

.slim {
  .item-footer {
    position: relative;
    padding-top: 30px;
    align-items: center;

    .item-responses {
      .last-response {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .item-footer {
    position: relative;
    padding-top: 30px;
    align-items: center;

    .item-responses {
      .last-response {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

@media(max-width: 480px) {
  .item-footer {
    .continue-reading {
      font-size: 14px;
    }
  }
}

@media(max-width: 400px) {
  .thread-listing-item {
    ::v-deep .avatar-sandwich {
      .avatar-container:nth-child(4) {
        display: none;
      }
    }
  }
}

@media(max-width: 360px) {
  .thread-listing-item {
    ::v-deep .avatar-sandwich {
      .avatar-container:nth-child(3) {
        display: none;
      }
    }
  }
}
</style>
