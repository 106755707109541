<template>
  <div>
    <b-loading
      v-if="loading"
      :is-full-page="true"
      :can-cancel="false"
      :active="true"
    />
    <template v-else>
      <template v-if="threadData.length > 0">
        <div>
          <thread-listing-item
            v-for="thread in threadData"
            :key="thread.id"
            :detail-route-name="detailRouteName"
            :project-identifier="projectIdentifier"
            :thread="thread"
            :slim="slim"
          />
        </div>
        <div
          v-if="limit"
          class="centered-button-container"
        >
          <grey-button
            :to="forumLink"
          >
            Ver todo
          </grey-button>
        </div>
      </template>
      <div
        v-else
        class="thread-listing-empty"
      >
        Aún no hay posteos disponibles
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import GreyButton from '../buttons/GreyButton.vue'
import ThreadListingItem from './ThreadListingItem.vue'

export default {
  name: 'ThreadListing',
  components: {
    GreyButton,
    ThreadListingItem
  },
  props: {
    detailRouteName: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      default: null
    },
    slim: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('forum', ['threadData', 'totalThreadCount']),
    ...mapState('participationProject', ['projectData']),
    projectIdentifier() {
      return this.$route.params.projectIdentifier
    },
    forumLink() {
      return { name: 'participant-forum', params: { projectIdentifier: this.projectIdentifier } }
    }
  },
  async created() {
    const options = { projectIdentifier: this.projectIdentifier }

    if (this.limit) {
      options.limit = this.limit
    }

    // Load thread data
    const success = await this.$store.dispatch(
      'forum/getThreadData',
      options
    )

    if (success) this.loading = false
  }
}
</script>
