<template>
  <router-link
    v-if="to"
    v-slot="{ href, navigate }"
    :to="to"
    class="grey-button"
  >
    <a
      :href="href"
      @click="navigate"
    >
      <slot />
    </a>
  </router-link>
  <a
    v-else
    class="grey-button"
    @click="click"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'GreyButton',
  props: {
    to: {
      type: Object,
      default: null
    },
    click: {
      type: Function,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.grey-button {
  font-weight: 500;
  font-size: 16px;
  color: $blue;
  height: 38px;
  line-height: 36px;
  white-space: nowrap;
  display: inline-block;
  padding: 0 19px;
  background: #fff;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.0250765);
  transition: $buttonBackgroundTransition;

  &:hover {
    background: #fafafa;
    border-color: #d0d0d0;
  }
}
</style>
