<template>
  <div class="avatar-sandwich">
    <div
      v-for="userData in usersData"
      :key="userData.identifier"
      class="avatar-container"
    >
      <avatar
        :user-data="userData"
        :size="40"
      />
    </div>
  </div>
</template>

<script>
import Avatar from './Avatar.vue'

export default {
  name: 'AvatarSandwich',
  components: {
    Avatar
  },
  props: {
    usersData: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-sandwich {
  display: flex;
}

.avatar-container {
  width: 30px;
  overflow: visible;
  position: relative;

  &:nth-child(1) {
    &:before {
      z-index: 90;
    }

    .avatar {
      z-index: 91;
    }
  }

  &:nth-child(2) {
    &:before {
      z-index: 80;
    }

    .avatar {
      z-index: 81;
    }
  }

  &:nth-child(3) {
    &:before {
      z-index: 70;
    }

    .avatar {
      z-index: 71;
    }
  }

  &:nth-child(3) {
    &:before {
      z-index: 60;
    }

    .avatar {
      z-index: 61;
    }
  }

  &:before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
  }

  .avatar {
    position: relative;
  }

  &:last-child {
    margin-right: 10px;
  }
}

.avatar {
  border: 3px solid #fff;
}
</style>
